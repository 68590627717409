import axios from "axios";

export async function subscribeNotifications() {
    try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array("BC7v353kVsA7cTOjwoa0hbMY23ekrDgZ7wpbV_-bSqatUfUjYVG9lnOUFy8bAJEkyLMBmbN1Y3OJgikIUwhQ-28")
        });

        await axios.post('/notification/subscribe', subscription);
        console.log('Subscribed!');
    } catch (error) {
        console.error(error);
    }
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}